.propertyReportTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--text);
}

.propertyReportDescription {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(32, 33, 36, 0.7);
}

.iframeURL {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--text);
}

.urlWrapper {
  width: 100%;
  height: 48px;
  overflow: hidden;
  padding: 16px;
  border-radius: 12px;
  background-color: #f7f7f7;
  margin: 0;

  color: #808183;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.iframeCustomTitle {
  color: var(--text);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
