/* Container */
.signin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container */
.signin-container {
  width: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.signinTextfieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 15px;
}

/* Form Title */
.signin-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Form Input Fields */
.signin-form-input {
  width: 100%;
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 5px;
  border: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.signin-form-input:focus {
  outline: none;
  box-shadow: 0px 0px 6px var(--brand);
}
/* Form Button */
.signin-form-button {
  width: 100%;
  padding: 10px;
  background-color: var(--brand);
  color: var(--white);
  font-weight: 800;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Form Button Hover */
.signin-form-button:hover {
  background-color: var(--brand);
}

/* Error Message */
.signin-error-message {
  color: red;
  margin-left: 4px;
}

/* Success Message */
.signin-success-message {
  color: var(--brand);
  margin-top: 10px;
}
