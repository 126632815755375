/* PasswordInput.module.css */

.passwordContainer {
position: relative;
font-family: Arial, sans-serif;
}

.passwordInput {
    width: 100%; /* Adjust width to make room for icon */
    padding: 10px;
    margin-top: 7px;
    margin-bottom: 5px;
    border: 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }
.eyeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

.passwordInput:focus {
outline: none;
box-shadow: 0px 0px 6px var(--brand);
}

.inputError {
border: 1px solid #ff5c5c;
}
.passwordInputWrapper {
    position: relative;
  }
.passwordRequirements {
    display: none; /* Hide by default, show it when input is focused */
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 10px;
    box-sizing: border-box;
    z-index: 10;
}
.hidden{
    display: none !important;
}
.visible{
    display: block !important;
}
.passwordRequirements.invalid_password{
    border: 1px solid #ff5c5c;
}

.passwordRequirements.valid_password{
    border: 1px solid #2ecc71;
}

.passwordInput:focus + .passwordRequirements,
.passwordInput:not(:placeholder-shown):not(:focus) + .passwordRequirements {
    display: block; /* Show when the input is focused or contains text */
}

.requirementsHeader {
    font-weight: bold;
    margin-bottom: 10px;
}
.requirementsHeader.valid_password{
    color: #2ecc71;
}
.requirementsHeader.invalid_password{
    color: #ff5c5c;
}


.requirementsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.requirementsList li {
    margin: 5px 0;
    font-size: 14px;
}

.valid {
    color: #2ecc71;
}

.invalid {
    color: #ff5c5c;
}

.valid::before,
.invalid::before {
    font-family: 'Courier New', Courier, monospace; /* to ensure the check and cross are monospaced */
    margin-right: 5px;
}

.valid::before {
    content: '✔';
}

.invalid::before {
    content: '✘';
}

.passwordStrength {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .strong {
    color: green;
  }
  
  .medium {
    color: orange;
  }
  
  .weak {
    color: red;
  }

  /* PasswordInput.module.css */

.passwordStrengthIndicator {
    height: 5px;
    width: 100%;
    background-color: #dcdcdc; /* Default background */
    border-radius: 3px;
    margin-top: 5px;
    position: relative;
  }
  
  .passwordStrengthBar {
    height: 100%;
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.3s ease;
  }
  
  .strong .passwordStrengthBar {
    width: 100%;
    background-color: green;
  }
  
  .medium .passwordStrengthBar {
    width: 50%; /* Adjust as needed */
    background-color: orange;
  }
  
  .weak .passwordStrengthBar {
    width: 25%; /* Adjust as needed */
    background-color: red;
  }
  
  .passwordStrength {
    margin-top: 10px;
    font-size: 14px;
    float: right;
    font-weight: 700;
  }
  
  /* ... rest of your styles */
  