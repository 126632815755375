.subscriptionCategoryPlanWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 4rem;
}

.subscriptionCategoryPlanBtn {
  min-width: 8.4rem;
  color: var(--text);
  border: none;
  width: 150px;
  font-size: 1.6rem;
  border-radius: 4px;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 0;
  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
  user-select: none;

  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.activePlan {
  background: var(--brand);
  color: var(--white);
}

.subscriptionWrapper {
  display: grid;
  width: 100%;
  gap: 2rem;
  margin-block: 1rem;
  justify-items: center;
}

.subscriptionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  position: relative;
  padding-bottom: 2rem;
  border-radius: 6px;
  gap: 2rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 310px;
  min-height: 287px;
}

.subscriptionCardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cardTitle {
  position: absolute;
  background-color: white;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 0.3rem 0;
  top: -10px;
  color: var(--text);
  font-size: 14px;
  font-weight: bolder;
  width: 100px;
  text-align: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.cardPrice {
  position: relative;
  color: var(--text);
  font-size: 36px;
  font-weight: bolder;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.contactUs {
  color: var(--text);
  font-size: 26px;
  font-weight: bolder;
}

.cardDollarSign {
  position: absolute;
  top: -5px;
  left: -12px;
  color: var(--text);
  font-size: 16px;
  font-weight: bolder;
}

.cardBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  min-width: 8.4rem;
  border: 0.1rem solid var(--brand);
  color: var(--white);
  background: var(--brand);
  width: 90%;

  font-size: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 2.2rem;
  padding: 0.5rem 0;
  border-radius: 0.8rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.cardItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.cardItem {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  align-tracks: center;
  gap: 1rem;
}

@media only screen and (max-width: 1024px) {
  .subscriptionWrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 640px) {
  .subscriptionWrapper {
    grid-template-columns: 100% !important;
  }
}
