/* Container */
.signup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container */
.signup-container {
  width: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.signupTextfieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 15px;
}

/* Form Title */
.signup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Form Input Fields */
.signup-form-input {
  width: 100%;
  padding: 10px;
  margin-top: 7px;
  margin-bottom: 5px;
  border: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.signup-form-input:focus {
  outline: none;
  box-shadow: 0px 0px 6px var(--brand);
}

/* Form Button */
.signup-form-button {
  width: 100%;
  padding: 10px;
  background-color: var(--brand);
  color: var(--white);
  font-weight: 800;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Form Button Hover */
.signup-form-button:hover {
  background-color: var(--brand);
}

/* Error Message */
.signup-error-message {
  color: red;
  margin-left: 4px;
}

/* Success Message */
.signup-success-message {
  color: var(--brand);
  margin-top: 10px;
}
