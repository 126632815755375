.sidebarContainer {
  width: 100%;
  height: 100%;
  margin-top: 24px;
  display: flex;
  gap: 24px;
}

.sidebar {
  background: #f7f7f7;
  padding: 0px 0 16px 24px;
  height: calc(100vh - 100px);
  overflow: auto;
  border-radius: 12px;
  transition: all 0.5s;
  padding-right:0px
}

.sidebarTitle {
  color: var(--text);
  font-weight: 700;
  font-size: 24px;
}

.sidebarItemContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.marginTop30vh{
  margin-top: 30vh;
}
.marginTop14vh{
  margin-top: 14vh;
}
.sidebarItemBottomContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.sidebarItem {
  color: var(--text);
  font-weight: 800;
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
}

.sidebarItem:hover {
  background: #dce2fb;
}

.sidebarItemActive {
  border-left: 4px solid var(--brand);
  border-radius: 4px;
  background: #dce2fb;
}

.sidebarSubItem {
  color: var(--text);
  font-weight: 400;
  padding: 10px 10px;
  font-size: 14px;
  cursor: pointer;
  padding-left: 28px;
}

.sidebarSubItem:hover {
  color: var(--brand);
}

.sidebarSubItemActive {
  color: var(--brand);
  font-weight: 500;
}

.endpointTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.endpointTitleContainer h1 {
  color: var(--text);
  font-weight: 600;
  font-size: 24px;
  margin: 0;
}

.endpointTitleContainer .method {
  background: var(--brand);
  color: var(--white);
  font-weight: 500;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 5px;
  line-height: 16px;
  margin-right: 8px;
}

.endpointTitleContainer .url {
  color: #20212499;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  overflow-wrap: anywhere;
  text-align: left;
}

.endpointDescription {
  margin-top: 24px;
  color: #202124b2;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.endpointHeader {
  margin-bottom: 24px;
}

.endpointHeader .symbol {
  color: var(--brand);
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 8px;
}

.endpointHeader .title {
  color: var(--text);
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.endpointExampleTable {
  background-color: #f7f7f7;
  height: 290px;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.endpointExampleTable .tableHeader {
  overflow: auto;
  display: flex;
  width: 100%;
  gap: 8px;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 8px;
}

.endpointExampleTable .tableHeader .tableHeaderItem {
  color: #202124;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  padding: 8px 12px;
  text-align: center;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
}

.endpointExampleTable .tableHeader .tableHeaderItem:hover,
.endpointExampleTable .tableHeader .active {
  background: #dce2fb;
  color: var(--brand);
}

.codeSnippetContainer {
  min-height: 150px;
  height: 150px;
}

.codeSnippetFooter {
  margin-top: 12px;
  cursor: pointer;
}

.parameterItem {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #f7f7f7;
  gap: 0.5rem;
  padding: 16px;
  margin-bottom: 24px;
}

.parameterItem .title {
  color: #202124;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.parameterItem .subTitle {
  display: flex;
  align-items: center;
  gap: 12px;
}

.parameterItem .subTitle .dataType,
.required {
  color: rgba(32, 33, 36, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid var(--brand);
  margin-block: 8px;
}

.parameterItem .body {
  color: rgba(32, 33, 36, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.responseItemContainer,
.parameterItemContainer {
  max-height: 300px;
  overflow: auto;
}

.responseItem {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #f7f7f7;
  gap: 0.5rem;
  padding: 16px;
  margin-bottom: 24px;
  cursor: pointer;
}

.responseItem .titleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.responseItem .titleContainer .title {
  color: #202124;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.responseItem .statusCode {
  color: var(--text);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid var(--brand);
  margin-block: 8px;
}

.modalTitle {
  color: #202124;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.sidebarOpen {
  min-width: 270px;
  max-width: 300px;
}

.sidebarClose {
  padding: 0;
  min-width: 0px;
  max-width: 0px;
  overflow: hidden;
}

.chevron {
  display: none;
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 3px;
  transition: all 0.6s;
  background: #f7f7f7;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    position: absolute;
    left: 0;
    top: 80px;
    height: calc(100vh - 80px);
    border-top-right-radius: 0px;
  }
  .chevron {
    display: block;
    position: absolute;
  }
}

@media only screen and (max-width: 640px) {
  .sidebar {
    top: 65px;
    height: calc(100vh - 65px);
  }
}
