.profileWrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-block: 1rem;
}

.profileWrapper img {
    background: #FFF;
    padding: 1rem;
    border-radius: 8px;
}

.profileWrapper h2 {
    margin: 0;
}