.keyWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  overflow: hidden;
}

.keyWrapper pre {
  padding: 0.7rem 1rem;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid #BABABA;
}
